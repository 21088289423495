// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Restaurant, Area, User, Sensor, Equipment, TemperatureReading, TemperatureRecord, TraceabilityLabel, Supplier, Product, Delivery, DeliveryPicture, CleaningTask, CleaningTaskRecord, Cooling, FryerCheck, FryerEquipment, SurfaceAnalysis, Reheating, Test, DateRange, Sub, Address, Settings, SensorSettings, GlobalSettings, TemperatureRecordsSettings, DeliverySettings, TraceabilitySettings, ContactMethod, DeliveryTemperatureRecord } = initSchema(schema);

export {
  Restaurant,
  Area,
  User,
  Sensor,
  Equipment,
  TemperatureReading,
  TemperatureRecord,
  TraceabilityLabel,
  Supplier,
  Product,
  Delivery,
  DeliveryPicture,
  CleaningTask,
  CleaningTaskRecord,
  Cooling,
  FryerCheck,
  FryerEquipment,
  SurfaceAnalysis,
  Reheating,
  Test,
  DateRange,
  Sub,
  Address,
  Settings,
  SensorSettings,
  GlobalSettings,
  TemperatureRecordsSettings,
  DeliverySettings,
  TraceabilitySettings,
  ContactMethod,
  DeliveryTemperatureRecord
};