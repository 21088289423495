import 'src/styles.css';

import { ChakraProvider } from '@chakra-ui/react';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { FC, useEffect } from 'react';
import { configure } from 'src/services/amplify';
import OneSignal from 'src/services/onesignal';
import theme from 'src/theme';
import { setColorMode } from 'src/utils/helpers/darkMode';
import Logger from 'src/utils/helpers/logger';
import { AuthProvider } from 'src/utils/hooks/useAuth';
import { DataStoreProvider } from 'src/utils/hooks/useDataStore';
configure();

const App: FC<AppProps> = ({ Component, pageProps }) => {
    useEffect(() => {
        defineCustomElements(window);
        OneSignal.init();
        setColorMode('light');
    }, []);

    return (
        <AuthProvider>
            <DataStoreProvider>
                <ChakraProvider theme={theme}>
                    <Logger />
                    <Component {...pageProps} />
                </ChakraProvider>
            </DataStoreProvider>
        </AuthProvider>
    );
};

export default appWithTranslation(App);
